.signup-container {
    text-align: left;
}

.signup-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    gap: 8px;
}

label {
    font-size: 14px;
    margin-bottom: 5px;
}

input[type="email"],
input[type="text"],
input[type="password"],
select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    background-color: white;
}

select {
    background-color: white;

}

.name-group {
    display: flex;
    justify-content: space-between;
}

.name-group .form-group {
    width: 48%;
}

.password-input {
    display: flex;
    align-items: center;
}

.password-input input {
    width: 85%;
}

.toggle-password {
    cursor: pointer;
    color: #006a75;
    margin-left: 10px;
}

.checkbox-group {
    display: flex;
    align-items: center;
}

.checkbox-group input {
    margin-right: 10px;
}

.submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #006a75;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #004e55;
}

.extra-links {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.extra-links a {
    font-size: 12px;
    color: #006a75;
    text-decoration: none;
}

.extra-links a:hover {
    text-decoration: underline;
}