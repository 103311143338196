.faq-container {
    max-width: 70%;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
}

.faq-category {
    margin-top: 20px;
    font-weight: bold;
    color: #16697A;
}

.MuiAccordion-root {
    border: 0px solid #ddd;
    margin-bottom: 10px;
    background-color: rgba(130, 192, 204, 0.2);

}

.MuiAccordionSummary {
    background-color: rgba(130, 192, 204, 0.2);
}

.MuiAccordionSummary-content {
    border: 0px solid #ddd;
    font-weight: bold;
    color: #16697A;
}

.MuiAccordionDetails-root {
    border: 0px solid #ddd;
    background-color: rgba(130, 192, 204, 0.2);
    color: #489FB5;
    padding: 15px;
}

.faq-answers {
    background-color: #82c0cc;
}