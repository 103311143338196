.password-form p {
  margin-bottom: 15px;
}



.password-form h3 {
  color: #16697a;
  margin-bottom: 20px;
}

.password-fields-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.password-form ol {
  margin-bottom: 15px;
  padding-left: 20px;
  color: #489fb5;
  /* Indent the ordered list */
}

.password-form p {
  font-weight: bold;

  color: #489fb5;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-field label {
  margin-bottom: 5px;
}

.password-group TextField {
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  font-size: 4px;
  width: 100%;
  box-sizing: border-box;
}