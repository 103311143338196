.requirement-container {
    max-height: 43vh;
    overflow-y: scroll;
}

.Grid-container {
    min-height: 48vh;
}

.requirement-container::-webkit-scrollbar {
    width: 10px;

}

.requirement-container::-webkit-scrollbar-thumb {
    background-color: #16697A;
    border-radius: 5px;
    border: 2px solid #ccc;
}

.requirement-container::-webkit-scrollbar-thumb:hover {
    background-color: #16697A;
}

.requirement-container::-webkit-scrollbar-track {
    background-color: #82C0CC;
    border-radius: 5px;
}