.company-data h3 {
  color: #16697a;
  margin-bottom: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.formGroup input,
.formGroup select {
  padding: 12px;
  border: 2px solid hsla(197, 7%, 21%, 0.50);
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}