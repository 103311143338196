@import url("../App.css");

.cardHeader {
  font-size: 0.7rem;
  background-color: var(--mainColor);
  padding-left: 2%;
  color: #fff;
  border-radius: 5px 5px 0 0;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-align: center;
  min-height: 8vh;
}

.cardHeader h2 {
  min-height: 5vh;
  align-content: center;
}

.cardHeader p {
  font-size: 1rem;
  margin-top: -1.12rem;
}

.cardHeader.no-description p {
  margin-top: 0;
}

.cardBody {
  padding: 16px;
  background-color: #82c0cc33;
  min-height: 55vh;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: flex-start;
  position: 'relative';
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
}

.customCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-self: center;
  margin: auto;
  height: 100%;
  margin-top: 2%;
}