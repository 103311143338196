/* Import the variables */
@import url("../../App.css");

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 1rem 2rem 0 2rem;
  background-color: var(--whiteColor);
  color: var(--textColor);
}

.logo {
  width: 27%;
  object-fit: contain;
}

nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -3%;
}

nav a {
  margin: 0 1rem;
  color: var(--mainColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.loginButton {
  background-color: #ffa62b;
}


@media only screen and (max-width: 124px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  nav a {
    font-size: 1.5rem;
    color: var(--whiteColor);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: var(--whiteColor);
  }



  header .responsive_nav {
    transform: translateY(100vh);
  }
}