.slider-list {
  padding: 20px;
}

.pie-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button {
  margin-top: 20px;
}

.action-buttons {
  margin-top: 30px;
}

.requirement-list {
  max-height: 48vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  display: flex;
  align-content: flex-start;
  padding-top: 20px;

}

.requirement-slider {
  max-height: 48vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  width: auto;
}