@import url("../../App.css");

.recent-projects .project-button {
  margin-bottom: 0.5rem;
  width: 100%;
  height: 3.5rem;
  color: var(--mainColor);
  background-color: var(--whiteColor);
  border: 0px solid #01565b;
  text-transform: none;
  font-weight: bold;
}

.project-button.active {
  background-color: var(--whiteColor);
  color: var(--mainColor);
  border: 3px solid #01565b;
}

.recent-projects {
  padding: 16px;
}


.project-button:hover {
  color: var(--mainColor);
  border: 3px solid #01565b;
}