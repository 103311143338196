.category-button-container {
  width: 100%;
}

.category-button-stack {
  width: 100%;
  align-items: flex-start;
}

.category-button {
  font-weight: 17px;
  text-transform: none;
  border-radius: 4px;
  white-space: nowrap;
}

.category-button.bold {
  font-weight: bold;
}

.category-button.active {
  background-color: #ffffff;
  color: #16697a;
}

.category-button.active:hover {
  background-color: #ffffff;
  color: #16697a;
}

.category-button:not(.active) {
  background-color: transparent;
  color: #489fb5;
}

.category-button:not(.active):hover {
  background-color: #ffffff;
  color: #16697a;
}