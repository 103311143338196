/* OverviewPage.css */
@import url("../../App.css");

/* Container for the Grid layout to ensure it takes the full width */
.grid-container {
  width: 100%;
}

/* Vertical Divider Styling */
.vertical-divider {
  background-color: var(--whiteColor);
  width: 2px;
  height: 30px;
  margin: 0 24px;
  border: none;
}


/* Style for the Project Overview button */
.overview-button {
  margin-top: 16px;
  /* Add some spacing at the top */
}

.recent-projects-container {
  height: 43vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.recent-projects-container::-webkit-scrollbar {
  width: 8px;
}

.recent-projects-container::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.recent-projects-container::-webkit-scrollbar-track {
  background: transparent;
}

.new-project {
  bottom: 0;
  width: 85%;
  height: 3.5rem;
  position: sticky;
}

.project-grid {}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55vh;
  width: 100%;
}