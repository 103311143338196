.personal-data {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.personal-data h3 {
  color: #16697a;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-group p {
  margin: 0;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 4px;
}

.form-group span {
  color: #489fb5;
}

/* Side-by-side layout for First and Last Name */
.form-group.side-by-side {
  display: flex;
  justify-content: space-between;
}

.form-group.side-by-side>div {
  flex: 1;
}

.form-group.side-by-side>div:last-child {
  margin-right: 0;
}

/* Styling for Select input */
.form-select {
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.linkedin-link {
  color: #0e76a8;
  /* LinkedIn blue color */
  text-decoration: none;
}

.linkedin-link:hover {
  text-decoration: underline;
  color: #005c8f;
  /* Darker shade of LinkedIn blue */
}