.selected-countries {
  padding: 0% 0% 0% 3%;
  gap: 1;
  min-height: 48vh;
}

.selected-countries-heading {
  color: #489fb5;
  font-size: 16px;
  text-align: left;
}

.selected-countries-div {
  overflow-y: auto;
  max-height: 40vh;
}

.scrollable-content {
  display: column;
}

.selected-countries-bar {
  display: flex;
  height: 35px;
  align-items: flex-start;
}

.selected-countries-id {
  background-color: #16697a;
  color: #ffffff;
  min-height: 20px;
  max-height: 40px;
  width: 30px;
  font-size: 15px;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}

.box-map {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  justify-items: center;
  background-color: red;
}


.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-icon {
  border: 1px solid #16697a;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
}

.close-icon:hover {
  color: white;
  background-color: #16697a;
}

.close-icons {
  border: 1px solid #16697a;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
}

.close-icons:hover {
  color: white;
  background-color: #16697a;
}

.grid-countries {
  padding-top: 2%;
}