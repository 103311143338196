/* SelectedProjectDataPage.css */

.project-card {
  background-color: #ffffff;
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  width: 100%;
  height: 43vh;
  scrollbar-width: thin;
}

.project-card-content {
  padding: 16px;
  max-height: 100%;

}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.continue-button {
  background-color: #01565b;
  color: white;
  text-transform: none;
  padding: 8px 16px;
}

.continue-button:hover {
  background-color: #013c3e;
}

.download-button,
.access-button,
.support-button {
  background-color: #01565b;
  color: white;
  text-transform: none;
  margin-top: 8px;
  width: 80%;
}

.download-button:hover,
.access-button:hover,
.support-button:hover {
  background-color: #013c3e;
}

.custom-step-icon-root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.custom-step-icon-circle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}

.custom-step-icon-active .custom-step-icon-circle {
  background-color: #01565b;
  color: white;
}

.custom-step-icon-inactive .custom-step-icon-circle {
  background-color: #a3d3d8;
  color: white;
}

.step-icon-number {
  font-weight: bold;
  font-size: 24;
}

.grid-container {

  justify-content: space-between;

}

.grid-item {
  flex: 5;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: left;
}

.button-align {

  text-align: right;
}

.divider {
  padding: 20px 0;
}

.button-stack {
  margin-top: 100px;
  width: 100%;
}