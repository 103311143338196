.instructions-box {
    background-color: transparent;
    border: 0px solid #ddd;
    border-radius: 8px;
    padding: 0;
    margin: 16px;
    color: #fff;
    text-align: left;
    font-size: 0.95rem;

}

.instructions-box h2 {
    font-size: 1.5rem;
    color: #fff;
    text-align: left;

}

.instructions-box p {
    font-size: 0.95rem;
    color: #fff;
    text-align: left;
    margin-top: -10px;
}