.App {
  text-align: center;
  min-height: 100vh;
}

:root {
  --mainColor: #116272;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #116272;
  --whiteColor: #ffffff;
}

.body {
  margin-top: 0%;
  padding-top: 0%;
  padding-bottom: 0%;
  min-height: 80vh;
}