.formRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 25px;
}

.formGroup {
  box-sizing: border-box;
  text-align: left;
  color: #313638;
}

.halfWidth {
  flex: 0 0 48%;
}

.quarterWidth {
  flex: 0 0 22%;
}

.formGroup input,
.formGroup select {
  padding: 12px;
  border: 2px solid hsla(197, 7%, 21%, 0.50);
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.profileForm h2 {
  text-align: center;
  color: #005f6b;
  font-size: 24px;
  margin-bottom: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.submitButton {
  padding: 10px 20px;
  background-color: #005f6b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 120px;
  text-align: center;
}

.submitButton:hover {
  background-color: #004c59;
}