/* Remove button styling */
.remove-btn {
    display: none;
    color: #e53935;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
}

.remove-btn:hover {
    color: #b71c1c;
    /* Darker red */
}

/* Priority Buttons (↑ or ↓) */
.priority-btn {
    color: #16697A;
    border: 1px solid #16697A;
    background: none;
    border-radius: 4px;
    padding: 2px 6px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.priority-btn:hover {
    background-color: #16697A;
    color: #ffffff;
}


.nav-button {
    width: 100px;
    text-transform: none;
    border-radius: 5px;
    border-color: #16697A;
}

.nav-button.back {
    background-color: #ffffff;
    color: #16697A;
    border: 1px solid #16697A;
}

.nav-button.back:hover {
    background-color: #16697A;
    color: #ffffff;
}

.nav-button.next {
    background-color: #16697A;
    color: #ffffff;
    border: none;
}


/* Heading and Dialog Styles */
.dialog-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.dialog-content {
    font-size: 1rem;
    color: #16697A;
}

.category-name {
    text-align: left;
    margin: 0;
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: bold;
    color: #489FB5;
}

.selected-requirements {
    max-height: 48vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #16697A #D9D9D9;
    scroll-behavior: smooth;
    align-items: flex-start;
    padding-right: 1%;

}

.requirement-box {
    align-items: flex-start;
}