.profile-page {
  display: flex;
}

.side-menu {
  width: 20%;
  background-color: transparent;
  padding: 20px;
}

.side-menu button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background: none;
  border: none;
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.side-menu button:hover {
  background-color: #e0e0e0;
}

.content {
  flex: 1;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-right: 80px;
  margin-bottom: 80px;
  margin-top: 20px;
  margin-left: 25px;
  background-color: #ffffff;
}

.vertical-divider {
  background-color: #ffffff;
  width: 3px;
  border: none;
}

.profile-page .selected {
  background-color: #ffffff;
  color: #16697a;
  font-weight: "bold";
  font-size: "16px";
}

.profile-page button:not(.selected) {
  color: #489fb5;
  font-weight: "normal";
  font-size: "16px";
}