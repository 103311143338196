.login-button {
  background-color: #16697a;
  color: #ffffff;
  text-transform: none;
  align-self: flex-start;
}

.login-button:hover {
  background-color: #16697a;
  color: #ffffff;
}

.error-message {
  color: red;
  text-align: center;
}

.link {
  color: #16697a !important;
  text-decoration: none !important;
  font-size: 18px;
}

.link:hover {
  color: #0f4c6f !important;
}


.checkbox-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.bottom-left-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;
}

input:-webkit-autofill {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}

input:-moz-autofill {
  background-color: white !important;
  box-shadow: 0 0 0 1000px white inset !important;
  color: #000 !important;
}

.MuiTextField-root .MuiOutlinedInput-root {
  border-radius: 5px;
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #16697a;
}

.MuiTextField-root .MuiInputLabel-outlined.Mui-focused {
  color: #16697a;
}

.MuiTextField-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #16697a;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #31363880;
  border-width: 2px;
}